import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RichEditor } from './RichEditor';
import * as DocumentService from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import ImportPdf from '../components/ImportPdf';
import RenameDocumentModal from './RenameDocumentModal';
import slugify from 'slugify';
import HyphenationSettingsModal from './HyphenationSettingsModal';
import startSlowingTimer from '../util/StartSlowingTimer';
import pLimit from 'p-limit';
import { removeStackedTags } from '../conversion/pdf/ImportPdf';
import EditDocumentRouteChangePrompt from './EditDocumentRouteChangePrompt';
import {
    convertCoverElementToEditorPage,
    downloadFile,
    htmlToBrailleFacil,
} from '../conversion/txt/BrailleFacilUtil';
import ShareDocumentModal from './ShareDocumentModal';
import DocumentSettingsModal from './DocumentSettingsModal';
import {
    extractImgData,
    isEditorElementImageLayout,
    getBrailleDocument,
    isShowingContextMenu,
    getClosestCover,
} from './editor-mods/modules/core/EditorUtil';
import { updateScroll } from './editor-mods/modules/ScrollModule';
import FloatButtonEditorImageElement from './FloatButtonEditorImageElement';
import {
    getImageUrlDataFromBackgroundImage,
    getDocumentStatusArray,
    DocumentStatusEnum,
    DocumentStatusEnumToString,
    getDocumentUrl,
    SaveDocumentValidationErrorEnum,
    SaveDocumentValidationErrorTxt,
    RoleEnumToString,
    isDocumentTypeImageDescription,
} from 'plataforma-braille-common';
import * as Sentry from '@sentry/react';
import { delay } from '../util/Delay';
import { exportPdf } from '../conversion/pdf/ExportPdf';
import { ImageMiniatureDialog } from './ImageMiniatureDialog';
import { RevisionModule } from './editor-mods/modules/revision/RevisionModule';
import { RevisionModal } from './editor-mods/modules/revision/RevisionModal';
import {
    cacheContent,
    getPageCache,
    setCacheDisabled,
    uncacheContent,
    unCacheUndoManager,
} from './editor-mods/modules/core/Cache';
import { exportDocx } from '../conversion/docx/ExportDocx';
import { getPages } from './editor-mods/modules/core/PageManipulation';
import DictionaryModal from '../dictionary/DictionaryModal';
import { SimpleViewModule } from './editor-mods/modules/simple-view/SimpleViewModule';
import { ZoomModule } from './editor-mods/modules/zoom/ZoomModule';
import { isEditorElementImage } from './editor-mods/modules/core/editor-element/EditorElementImage';
import FindReplaceModal from './FindReplaceModal';
import FiguresModal from './FiguresModal';
import CoversModal from './CoversModal';
import IdentificationModal from '../identification/IdentificationModal';
import PaginationModal from './PaginationModal';
import { SummaryModal } from './editor-mods/modules/summary/SummaryModal';
import { FormatPainterModule } from './editor-mods/modules/FormatPainterModule';
import { RevisionErrorEnum } from './editor-mods/modules/revision/RevisionErrorEnum';
import CoversContentModal from './CoversContentModal';

/**
 * @typedef {object} EditorElementFocusedEvent
 * @property {HTMLElement} element
 */

/**
 * @returns {EditorCustom | null}
 */
export function getActiveEditor() {
    /**
     * @type {any | undefined}
     */
    const editor = window?.tinymce?.activeEditor;
    return editor ?? null;
}

/**
 * Upload images does not dispose the loading modal
 */
export async function uploadImages(setLoadingFn, querySelectorFn) {
    // I18N
    const loadingMessage = 'Enviando imagens...';
    setLoadingFn(true, false, loadingMessage);
    const limit = pLimit(8);
    const images = [
        ...querySelectorFn('editor-element[type="image"]'),
        ...querySelectorFn('editor-element[type="image-layout"]')
    ];
    let uploaded = 0;
    const promises = [];
    for (let i = 0; i < images.length; i++) {
        const imageElement = images[i];
        let imageData = getImageUrlDataFromBackgroundImage(
            imageElement.style.backgroundImage || imageElement.getAttribute('data-image'),
        );

        if (
            imageData?.startsWith('data:image/') ||
            imageData?.startsWith('blob:')
        ) {
            promises.push(
                limit(async () => {
                    const imgData = await extractImgData(imageData);
                    const fileName = `img-${Date.now()}.${imgData.type}`;
                    const url = await DocumentService.createFile(
                        fileName,
                        imgData.base64,
                    );
                    const backgroundUrl = `url("${url}")`;
                    if (isEditorElementImage(imageElement)) {
                        imageElement.style.backgroundImage = backgroundUrl;
                    } else if (isEditorElementImageLayout(imageElement)) {
                        imageElement.setAttribute('data-image', backgroundUrl);
                    }
                    setLoadingFn(
                        true,
                        false,
                        loadingMessage,
                        (++uploaded / promises.length) * 100,
                    );
                }),
            );
        }
    }
    return Promise.all(promises);
}

/**
 * @param fetchImageElementsFn {function(domQuery: string):HTMLElement[]}
 */
export function prepareImageElementsToRetrieveImagesDescriptionsBackground(
    fetchImageElementsFn,
) {
    for (const imageElement of fetchImageElementsFn(
        'editor-element[type="image"]',
    )) {
        const imageData = getImageUrlDataFromBackgroundImage(
            imageElement.style.backgroundImage,
        );
        const infoDescription = imageElement.querySelector('.info-description');
        if (!infoDescription) {
            console.error('Image element corrupted.', imageElement);
            continue;
        }
        const description = infoDescription.innerText?.trim();
        if (!imageData || description) {
            continue;
        }
        infoDescription.setAttribute('contentEditable', 'false');
        imageElement.setAttribute('data-loading-background', 'true');
    }
}

export function EditorDocument() {
    const { id, name } = useParams();
    /**
     * @type {BrailleDocument | null}
     */
    const docInitialValue = null;
    const [doc, setDoc] = useState(docInitialValue);
    let [hasChange, setHasChange] = useState(false);

    const [showHyphenationSettingsModal, setShowHyphenationSettingsModal] =
        useState(false);
    const [showDictionaryModal, setShowDictionaryModal] = useState(false);
    const [showIdentificationModal, setShowIdentificationModal] =
        useState(false);
    const [showPaginationModal, setShowPaginationModal] = useState(false);
    const [showDocumentSettingsModal, setShowDocumentSettingsModal] =
        useState(false);
    /**
     * @type {HTMLElement | null}
     */
    const editorElementImageFocusedInitialValue = null;
    const [editorElementImageFocused, setEditorElementImageFocused] = useState(
        editorElementImageFocusedInitialValue,
    );
    /**
     * @type {HTMLElement | null}
     */
    const editorCoverElementSelectedInitialValue = null;
    const [editorCoverElementSelected, setEditorCoverElementSelected] =
        useState(editorCoverElementSelectedInitialValue);
    /**
     * @type {HTMLElement | null}
     */
    const editorElementImageLayoutFocusedInitialValue = null;
    const [
        editorElementImageLayoutFocused,
        setEditorElementImageLayoutFocused,
    ] = useState(editorElementImageLayoutFocusedInitialValue);
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        user,
        isAdmin,
        isEvaluator,
        isModerator,
    } = useContext(EnvironmentContext);
    /**
     * @type {React.MutableRefObject<ImportPdfFunctions | null>}
     */
    const importPdfRef = useRef(null);
    /**
     * @type {React.MutableRefObject<RichEditorFunctions | null>}
     */
    const richEditorRef = useRef(null);
    const hasChangeRef = useRef(false);
    /**
     * @type {React.MutableRefObject<number | null>}
     */
    const documentLockIdRef = useRef(null);
    /**
     * Avoids multiple parallels fetching on initialization (after create a document this happens for an uncheck reason)
     * @type {React.MutableRefObject<boolean>}
     */
    const fetchingDocumentRef = useRef(false);
    const defaultTitle = window.document.title;
    const navigate = useNavigate();
    /**
     * @type {React.MutableRefObject<number | null>}
     */
    const tCheckRetrieveImageBackgroundRef = useRef(null);
    /**
     * @type {React.MutableRefObject<RenameDocumentModalFunctions | null>}
     */
    const renameDocumentModalRef = useRef(null);
    /**
     * @type {React.MutableRefObject<ShareDocumentModalFunctions | null>}
     */
    const shareDocumentModalRef = useRef(null);
    /**
     * @type {React.MutableRefObject<RevisionModalFunctions | null>}
     */
    const revisionModalRef = useRef(null);
    /**
     * @type {React.MutableRefObject<SummaryModalFunctions | null>}
     */
    const summaryModalRef = useRef(null);

    /**
     * @type {React.MutableRefObject<ButtonRetrieveDescriptionAiFunctions | null>}
     */
    const buttonRetrieveDescriptionAiRef = useRef(null);

    const [isFiguresModalOpen, setIsFiguresModalOpen] = useState(false);
    const [showModalCover, setShowModalCover] = useState(false);
    const [showModalCoverContent, setShowModalCoverContent] = useState(false);
    const [showModalFindReplace, setShowModalFindReplace] = useState(false);

    function back() {
        navigate('/');
    }

    useEffect(() => {
        setLoading(true);

        return () => {
            window.document.title = defaultTitle;
        };
    }, []);

    useEffect(() => {
        setLoading(false);
        if (!fetchingDocumentRef.current && !doc) {
            // noinspection JSIgnoredPromiseFromCall
            fetchDocument();
        }
    }, []);

    useEffect(() => {
        hasChangeRef.current = hasChange;
    }, [hasChange]);

    useEffect(() => {
        hasChangeRef.current = hasChange;
    }, [hasChange]);

    function setDocumentTitle(title) {
        window.document.title = `${defaultTitle} - ${title}`;
        richEditorRef.current?.setDocumentTitle(title);
    }

    /**
     * @return {Promise<void>}
     */
    async function checkRetrieveImageBackground() {
        if (!richEditorRef.current) {
            return;
        }
        const editor = richEditorRef.current?.getEditor();
        let hasChanges = false;
        const selector =
            'editor-element[type="image"][data-loading-background="true"]';
        let allSolved = true;
        const pages = getPages(editor);
        if (!pages) return;
        const brailleDocument = getBrailleDocument(editor);
        for (let page of pages) {
            const cache = getPageCache(editor, page);
            page = cache ?? page;
            const elements = page.querySelectorAll(selector);
            for (const imageElement of [...elements]) {
                const infoDescription =
                    imageElement.querySelector('.info-description');
                if (!infoDescription) {
                    console.warn('Image element corrupted.', infoDescription);
                    continue;
                }
                try {
                    const imgUrl = getImageUrlDataFromBackgroundImage(
                        imageElement.style.backgroundImage,
                    );
                    const response =
                        await DocumentService.imageDescriptionBackground(
                            imgUrl,
                            brailleDocument.id,
                        );
                    if (response) {
                        hasChanges = true;
                        infoDescription.setAttribute('contentEditable', 'true');
                        if (response.error) {
                            imageElement.setAttribute(
                                'data-loading-background',
                                'error',
                            );
                        } else {
                            imageElement.removeAttribute(
                                'data-loading-background',
                            );
                            infoDescription.innerText = response.description;
                        }
                    }
                } catch (e) {
                    console.error(
                        'Fail to retrieve image description in background.',
                        e,
                    );
                }
            }
            const result = page.querySelectorAll(selector);
            allSolved = allSolved && result.length === 0;
            if (hasChanges) {
                setHasChange(true);
            }
            await delay(100);
        }
        if (!allSolved) {
            tCheckRetrieveImageBackgroundRef.current = setTimeout(
                checkRetrieveImageBackground,
                60000,
            );
        } else {
            tCheckRetrieveImageBackgroundRef.current = null;
            if (hasChanges) {
                richEditorRef.current?.getNotificationManager().open({
                    // I18N
                    text: 'Descrições concluídas',
                    type: 'info',
                });
            }
        }
    }

    useEffect(() => {
        if (!doc) return;
        if (doc.lockedByUser) {
            setInfoModal({
                // I18N
                title: 'Documento bloqueado',
                // I18N
                message:
                    'O documento está aberto no modo somente leitura, pois está atualmente sendo editado por ' +
                    `<strong>${doc.lockedByUser?.trim()}</strong>.`,
                show: true,
            });
        } else {
            // #43222
            if (doc.readOnly) {
                /**
                 * @type {import('plataforma-braille-common').DocumentStatusEnumValue[] | any}
                 */
                const documentStatusArray = getDocumentStatusArray();
                const currentStatusIdx = documentStatusArray.indexOf(
                    doc.status,
                );
                let userRoles = doc.userWithAccess
                    .filter((record) => {
                        return record.id === user.id;
                    })
                    .map((record) => record.roles)
                    .map((record) => {
                        return record.map((record) => {
                            return documentStatusArray.indexOf(record);
                        });
                    });
                let userRoleIdx = userRoles.length
                    ? userRoles.sort((a, b) => a - b)[0][0]
                    : 0;
                if (userRoleIdx > currentStatusIdx) {
                    // check if user can edit in future pipeline
                    const roleDescription = RoleEnumToString(
                        documentStatusArray[userRoleIdx],
                    );
                    setInfoModal({
                        title: 'Atenção',
                        message:
                            `Este documento ainda não foi liberado para <strong>${roleDescription}</strong>.<br>` +
                            'Não se preocupe, assim que estiver disponível você receberá uma notificação no e-mail cadastrado.',
                        show: true,
                    });
                }
            }

            documentLockIdRef.current = doc.lockId;

            const tKeepDocumentLock = setInterval(async () => {
                try {
                    await DocumentService.keepDocumentLock(id, doc.lockId);
                    console.debug('Lock fed.');
                } catch (e) {
                    if (
                        e.response?.status === 422 &&
                        e.response?.data?.errors?.includes(
                            SaveDocumentValidationErrorEnum.USER_REQUESTED_UNLOCK,
                        )
                    ) {
                        try {
                            await editDocument();
                            console.info(
                                'Document successfully saved before going locked by another user.',
                            );
                            // I18N
                            setLoading(true, false, 'Por favor, aguarde...');
                            await DocumentService.unlockDocument(
                                doc.id,
                                documentLockIdRef.current,
                            );
                            await delay(10000);
                            await fetchDocument();
                            richEditorRef.current?.forceUpdateDoc();
                        } catch (e) {
                            backendConnectionError(
                                'Fail to save document before being locked by another user',
                                e,
                            );
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        throw e;
                    }
                }
            }, 30000);

            const tAutoSave = setInterval(async () => {
                if (hasChangeRef.current) {
                    await editDocument(null, true);
                    console.debug('Document auto saved.');
                }
            }, 300000);

            const tRetrieveImageBackground = setTimeout(
                checkRetrieveImageBackground,
                5000,
            );

            function releaseLock() {
                DocumentService.unlockDocument(
                    id,
                    documentLockIdRef.current,
                ).then(() => {
                    console.debug('Document successfully unlocked.');
                });
            }

            window.addEventListener('beforeunload', releaseLock);

            return () => {
                // noinspection JSIgnoredPromiseFromCall
                releaseLock();
                window.removeEventListener('beforeunload', releaseLock);
                clearInterval(tKeepDocumentLock);
                clearInterval(tAutoSave);
                clearTimeout(tRetrieveImageBackground);
                clearTimeout(tCheckRetrieveImageBackgroundRef.current);
            };
        }
    }, [doc]);

    /**
     * @returns {Promise<void>}
     */
    async function fetchDocument() {
        try {
            fetchingDocumentRef.current = true;
            setLoading(true);
            let document = await DocumentService.getDocument(
                id,
                name,
                true,
                true,
            );
            document = {
                ...document,
                html: removeStackedTags(
                    document.html ?? '<editor-page>&nbsp;</editor-page>',
                ),
            };
            setDoc(document);
            setDocumentTitle(document.name);
            if (!document.lockedByUser) {
                // not locket to another user, but to you
                console.debug('Document locked.');
            }
        } catch (e) {
            const status = e.response?.status;
            if (status === 404) {
                // I18N
                setInfoModal({
                    title: 'Abrir documento',
                    message:
                        'Documento não encontrado. Verifique se foi excluído ou renomeado e tente novamente.',
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch document.', e, back);
            }
        } finally {
            fetchingDocumentRef.current = false;
            setLoading(false);
        }
    }

    function importPdf() {
        importPdfRef.current?.openFile();
    }

    /**
     * @param newStatus {import('plataforma-braille-common').DocumentStatusEnumValue | undefined}
     * @param silent {boolean | undefined}
     * @returns {Promise<void>}
     */
    async function editDocument(newStatus = undefined, silent = false) {
        const editor = richEditorRef.current?.getEditor();
        const cursorPosition = richEditorRef.current?.getSelection().getRng();
        let slowingTimer;
        try {
            // I18N
            const loadingMessage = 'Salvando documento...';
            setLoading(!silent, false, loadingMessage);
            uncacheContent(editor);
            await uploadImages(!silent ? setLoading : () => {}, (selector) =>
                richEditorRef.current?.getDom().select(selector),
            );

            if (!silent) {
                slowingTimer = startSlowingTimer(5000, (progress) => {
                    // I18N
                    setLoading(true, false, loadingMessage, progress * 100);
                });
            }
            const html = await richEditorRef.current?.getHtml();
            await DocumentService.editDocument(id, {
                html,
                status: newStatus,
                lockId: doc.lockId,
            });

            await updateScroll(editor);
            setLoading(true, false, loadingMessage, 100);
            setHasChange(false);
            cacheContent(editor);
            richEditorRef.current?.getNotificationManager().open({
                // I18N
                text: 'Documento salvo com sucesso',
                type: 'success',
                timeout: 5000,
            });
        } catch (e) {
            if (!silent) {
                backendConnectionError(
                    'Fail to edit document.',
                    e,
                    null,
                    // I18N
                    'Salvar',
                    SaveDocumentValidationErrorTxt,
                );
            } else {
                Sentry.captureException(e);
                console.error('Fail to save document silently', e);
                richEditorRef.current?.getNotificationManager().open({
                    // I18N
                    text: 'Erro ao salvar documento',
                    type: 'error',
                    timeout: 15000,
                });
            }
        } finally {
            slowingTimer?.cancel();
            setLoading(false);
            if (!silent) {
                setTimeout(() => {
                    richEditorRef.current?.focus();
                    richEditorRef.current
                        ?.getSelection()
                        ?.setRng(cursorPosition);
                }, 250);
            }
        }
    }

    function renameDocument() {
        renameDocumentModalRef.current?.showRenameDocumentModal(
            parseInt(id),
            async (entity) => {
                setDocumentTitle(entity.name);
                const newUrl = slugify(entity.name, { lower: true });
                if (name !== newUrl) {
                    console.debug('Document URL changed.');
                    await editDocument(); // save before change url to avoid data loss
                    navigate(getDocumentUrl(entity));
                }
            },
        );
    }

    function userWithAccess() {
        shareDocumentModalRef.current?.showShareDocumentModal(
            doc,
            (userWithAccess) => {
                richEditorRef.current?.updateDocumentDesignatedUsers(
                    userWithAccess,
                );
            },
        );
    }

    /**
     * @param newStatus {import('plataforma-braille-common').DocumentStatusEnumValue | null | undefined}
     */
    function changeDocumentStatus(newStatus = null) {
        let statusStr;
        if (!newStatus) {
            /**
             * @type {import('plataforma-braille-common').DocumentStatusEnumValue[] | any}
             */
            const documentStatusArray = getDocumentStatusArray();
            const brailleDocument = getBrailleDocument(
                richEditorRef.current?.getEditor(),
            );
            const currentStatus =
                brailleDocument.status ?? DocumentStatusEnum.LINEARIZATION;
            const currentStatusIdx = documentStatusArray.indexOf(currentStatus);
            newStatus = documentStatusArray[currentStatusIdx + 1];
            statusStr =
                DocumentStatusEnumToString(currentStatus)?.toLowerCase();
        } else {
            statusStr = DocumentStatusEnumToString(newStatus)?.toLowerCase();
        }

        let title;
        let message;
        let cancelText;
        let confirmText;
        let onCancel;

        const editor = richEditorRef.current?.getEditor();
        const brailleDocument = getBrailleDocument(editor);
        const { revisionModule } = editor.custom;
        const revisionRecords = revisionModule?.getAllRevisionRecords();
        if (
            newStatus &&
            newStatus !== brailleDocument.status &&
            !!revisionRecords?.length
        ) {
            title = `Alterar status para ${statusStr}`;
            message =
                'Problemas encontrados na revisão do documento. Como deseja prosseguir?';
            cancelText = 'Voltar para corrigir os erros de revisão';
            confirmText = 'Ignorar erros e alterar status';
            onCancel = () => revisionModule?.showRevisionModal();
        } else {
            // I18N
            title = 'Enviar para';
            // I18N
            message =
                isAdmin || isEvaluator
                    ? `Tem certeza que deseja alterar o status do documento para <strong>${statusStr}</strong>?`
                    : `Tem certeza que deseja encerrar a etapa de <strong>${statusStr}</strong>?`;
        }

        setConfirmModal({
            title,
            message,
            show: true,
            cancelText,
            confirmText,
            onConfirm: async () => {
                setLoading(true);
                try {
                    await editDocument(newStatus);
                    await DocumentService.unlockDocument(doc.id, doc.lockId);
                    await fetchDocument();
                    richEditorRef.current?.forceUpdateDoc();
                } catch (e) {
                    backendConnectionError('Fail to edit document.', e);
                } finally {
                    setLoading(false);
                }
            },
            onCancel,
        });
    }

    function removeDocument() {
        setConfirmModal({
            // I18N
            title: 'Remover documento',
            // I18N
            message:
                'Tem certeza que deseja remover este documento? <strong>Essa operação não poderá ser desfeita</strong>.',
            show: true,
            onConfirm: async () => {
                // I18N
                setLoading(true, false, 'Removendo documento...');
                try {
                    await DocumentService.removeDocument(id);
                    setHasChange(false);
                    setInfoModal({
                        // I18N
                        title: 'Remover documento',
                        // I18N
                        message: 'Documento removido com sucesso.',
                        show: true,
                        onClose: () => {
                            back();
                        },
                    });
                } catch (e) {
                    backendConnectionError('Fail to remove document.', e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    async function exportTxt() {
        async function doExport() {
            // noinspection JSUnresolvedReference
            const html = await richEditorRef.current.getHtml();
            const editor = richEditorRef.current?.getEditor();
            const brailleDocument = getBrailleDocument(editor);

            let coverPage;
            const regex = /<cover-page>.*?<\/cover-page>/;
            const matchCover = html.match(regex);

            if (matchCover) {
                coverPage = matchCover[0];
            }

            const htmlArray = html
                .replace(/<cover-page>.*?<\/cover-page>/, '')
                .split(/<document-break>.*?<\/document-break>/)
                .map((part) => part.trim())
                .filter(Boolean);

            const txtArray = htmlArray.map((htmlEl, index) => {
                let coverPageElement = '';
                let technicalSheetElement = '';

                if (coverPage) {
                    const convertedElement = convertCoverElementToEditorPage(
                        coverPage,
                        htmlArray.length,
                        index + 1,
                        brailleDocument.brailleCellRowCount,
                        brailleDocument.brailleCellColCount,
                    );

                    const matchConvertedElement = convertedElement.match(
                        /<editor-page>.*?<\/editor-page>/g,
                    );
                    coverPageElement =
                        matchConvertedElement[0] + matchConvertedElement[1]; //Cover and backCover
                    technicalSheetElement = matchConvertedElement[2] || ''; //technicalSheet
                }
                return htmlToBrailleFacil(
                    `${coverPageElement}${htmlEl}${technicalSheetElement}`,
                    editor.custom.coreModule.editorElements,
                    doc,
                );
            });
            await downloadFile(doc.name, txtArray);
        }

        const revisionModule =
            richEditorRef.current?.getEditor().custom?.revisionModule;
        if (!revisionModule) {
            await doExport();
            return;
        }

        if (
            revisionModule.hasRevisionErrors(
                RevisionErrorEnum.IMAGE_NEEDS_REVIEW,
            )
        ) {
            setConfirmModal({
                // I18N
                title: 'Exportar TXT',
                // I18N
                message:
                    'O documento contém imagens que precisam ser revisadas. Deseja exportar o arquivo mesmo assim?',
                // I18N
                cancelText: 'Voltar para o documento',
                // I18N
                confirmText: 'Exportar TXT',
                show: true,
                onConfirm: () => doExport(),
            });
            return;
        }
        await doExport();
    }

    /**
     * @param editor {EditorCustom}
     * @param callback {function(scale:number, pages:HTMLElement[]):Promise}
     * @return {Promise<void>}
     */
    async function prepareDataToExport(editor, callback) {
        const editorPages = richEditorRef.current
            ?.getEditor()
            .dom.select('editor-page');
        const scale = 1 / editor.custom.zoom;
        try {
            setCacheDisabled(editor, true);
            uncacheContent(editor);
            await callback(scale, editorPages);
        } finally {
            setCacheDisabled(editor, false);
            cacheContent(editor);
            setLoading(false);
        }
    }

    /**
     * @param editor {EditorCustom}
     * @return {Promise<void>}
     */
    async function exportPdfAction(editor) {
        setLoading(true, false, 'Gerando PDF...');
        try {
            await prepareDataToExport(editor, async (scale, editorPages) => {
                await exportPdf(
                    getBrailleDocument(editor),
                    scale,
                    editorPages,
                    (current, total) => {
                        setLoading(
                            true,
                            false,
                            'Gerando PDF...',
                            Math.round((current / total) * 100),
                        );
                    },
                );
            });
        } catch (e) {
            Sentry.captureException(e);
            console.error('Fail to create PDF.', e);
            setInfoModal({
                title: 'Exportar PDF',
                message:
                    '<strong>Falha ao gerar PDF</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.',
                show: true,
            });
        }
    }

    async function exportDocxAction(editor) {
        setLoading(true, false, 'Gerando DOCX...');
        try {
            await prepareDataToExport(editor, async (scale, editorPages) => {
                await exportDocx(
                    getBrailleDocument(editor),
                    editorPages,
                    (current, total) => {
                        setLoading(
                            true,
                            false,
                            'Gerando DOCX...',
                            Math.round((current / total) * 100),
                        );
                    },
                );
            });
        } catch (e) {
            Sentry.captureException(e);
            console.error('Fail to create DOCX.', e);
            setInfoModal({
                title: 'Exportar DOCX',
                message:
                    '<strong>Falha ao gerar DOCX</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.',
                show: true,
            });
        }
    }

    function forceUnlockDocument() {
        // I18N
        setConfirmModal({
            title: 'Desbloquear documento para edição',
            message:
                'Tem certeza que deseja desbloquear o documento para edição?',
            onConfirm: async () => {
                // I18N
                const loadingMessage = 'Desbloqueando documento...';
                let slowingTimer;
                try {
                    let status;
                    slowingTimer = startSlowingTimer(60000, (progress) => {
                        setLoading(true, false, loadingMessage, progress * 100);
                    });
                    do {
                        status = await DocumentService.unlockDocument(
                            id,
                            null,
                            true,
                        );
                        if (status !== 200) {
                            await delay(5000);
                        }
                    } while (status !== 200);
                    await fetchDocument();
                    richEditorRef.current?.forceUpdateDoc();
                } catch (e) {
                    backendConnectionError(
                        'Fail to unlock document forced.',
                        e,
                    );
                } finally {
                    slowingTimer?.cancel();
                    setLoading(false);
                }
            },
            show: true,
        });
    }

    async function retrieveImagesDescriptionsBackground() {
        const editor = richEditorRef.current?.getEditor();
        try {
            uncacheContent(editor);
            prepareImageElementsToRetrieveImagesDescriptionsBackground(
                (domQuery) => editor.dom.select(domQuery),
            );
            await editDocument(null, false);
            setLoading(true);
            await DocumentService.retrieveImagesDescriptionsBackground(doc.id);
            setInfoModal({
                title: 'Gerar descrições em segundo plano',
                message:
                    'Descrições em andamento. Você será notificado quando o processo estiver concluído.',
                show: true,
            });
            if (!tCheckRetrieveImageBackgroundRef.current) {
                checkRetrieveImageBackground().then();
            }
        } catch (e) {
            backendConnectionError(
                'Fail to retrieve images descriptions in background.',
                e,
            );
        } finally {
            setLoading(false);
            cacheContent(editor);
        }
    }

    function loadingPdf(show, progress) {
        // I18N
        setLoading(show, false, 'Importando PDF...', progress ?? 0);
    }

    /**
     *
     * @param callback {function}
     */
    function onIdentificationSuppressInAllPagesAction(callback) {
        setConfirmModal({
            // I18N
            title: 'Suprimir Identificação',
            message:
                'Deseja realmente suprimir a identificação em todo o documento?',
            show: true,
            onConfirm: callback,
        });
    }

    /**
     *
     * @param callback {function}
     */
    function onIdentificationExposeInAllPagesAction(callback) {
        setConfirmModal({
            // I18N
            title: 'Expor Identificação',
            message:
                'Deseja realmente expor a identificação em todo o documento?',
            show: true,
            onConfirm: callback,
        });
    }

    if (!doc) {
        return <></>;
    }

    return (
        <>
            <EditDocumentRouteChangePrompt
                enabled={hasChange}
                onSave={() => editDocument()}
            />
            <RenameDocumentModal ref={renameDocumentModalRef} />
            <ShareDocumentModal ref={shareDocumentModalRef} />
            <HyphenationSettingsModal
                documentId={id}
                show={showHyphenationSettingsModal}
                onFinished={async (document) => {
                    const updatedDoc = {
                        ...doc,
                        ...document,
                    };
                    setDoc(updatedDoc);
                    richEditorRef.current.getEditor().custom.brailleDocument =
                        updatedDoc;
                    setShowHyphenationSettingsModal(false);
                    if (document) await richEditorRef.current?.updatePages();
                }}
            />
            <DictionaryModal
                show={showDictionaryModal}
                onClose={() => setShowDictionaryModal(false)}
            />
            <IdentificationModal
                show={showIdentificationModal}
                onClose={() => setShowIdentificationModal(false)}
                editor={richEditorRef.current?.getEditor()}
            />
            <DocumentSettingsModal
                documentId={id}
                show={showDocumentSettingsModal}
                onFinished={async (document) => {
                    const updatedDoc = {
                        ...doc,
                        ...document,
                    };
                    setDoc(updatedDoc);
                    richEditorRef.current.getEditor().custom.brailleDocument =
                        updatedDoc;
                    setShowDocumentSettingsModal(false);
                    if (document) await richEditorRef.current?.updatePages();
                }}
            />
            <ImportPdf
                ref={importPdfRef}
                onPdfLoaded={async ({ html }) => {
                    setHasChange(true);
                    await richEditorRef.current?.setHtml(html);
                    if (isDocumentTypeImageDescription(doc.type)) {
                        await editDocument();
                        doc.html = html;
                        await retrieveImagesDescriptionsBackground();
                    }
                }}
                onError={(error, defaultErrorHandlingFn) => {
                    defaultErrorHandlingFn();
                }}
                onLoading={(loading) => {
                    loadingPdf(loading);
                }}
                onProgress={(current, total, abort) => {
                    const destroyed =
                        richEditorRef.current?.getEditor()?.custom.destroyed ||
                        richEditorRef.current?.getEditor()?.custom.destroyed ==
                            null;
                    if (destroyed) {
                        abort();
                        loadingPdf(false);
                    } else {
                        loadingPdf(true, (current / total) * 100);
                    }
                }}
                documentType={doc.type}
            />
            <FloatButtonEditorImageElement
                ref={buttonRetrieveDescriptionAiRef}
                editor={richEditorRef.current?.getEditor()}
                attachEditorElement={editorElementImageFocused}
                uploadImages={async () =>
                    await uploadImages(setLoading, (selector) =>
                        richEditorRef.current?.getDom().select(selector),
                    )
                }
            />
            <ImageMiniatureDialog
                editor={richEditorRef.current?.getEditor()}
                attachEditorElement={editorElementImageLayoutFocused}
            />
            <RevisionModal
                ref={revisionModalRef}
                editor={richEditorRef.current?.getEditor()}
            />

            <SummaryModal
                ref={summaryModalRef}
                editor={richEditorRef.current?.getEditor()}
            />

            <FindReplaceModal
                editor={richEditorRef.current?.getEditor()}
                textSearch={''}
                show={showModalFindReplace}
                onFinished={() => setShowModalFindReplace(false)}
            />

            <FiguresModal
                editor={richEditorRef.current?.getEditor()}
                show={isFiguresModalOpen}
                onClose={() => setIsFiguresModalOpen(false)}
            />

            <CoversModal
                editor={richEditorRef.current?.getEditor()}
                show={showModalCover}
                onClose={() => setShowModalCover(false)}
            />

            <CoversContentModal
                editor={richEditorRef.current?.getEditor()}
                show={showModalCoverContent}
                onClose={() => {
                    setShowModalCoverContent(false);
                    setEditorCoverElementSelected(null);
                }}
                coverElement={editorCoverElementSelected}
            />

            <PaginationModal
                show={showPaginationModal}
                documentId={id}
                onClose={() => setShowPaginationModal(false)}
                editor={richEditorRef.current?.getEditor()}
            />

            <RichEditor
                ref={richEditorRef}
                doc={doc}
                onImportPdf={importPdf}
                onEditDocument={() => editDocument()}
                onRemoveDocument={removeDocument}
                onRename={renameDocument}
                onHyphenationSettings={() =>
                    setShowHyphenationSettingsModal(true)
                }
                onDocumentSettings={() => setShowDocumentSettingsModal(true)}
                onUserWithAccess={userWithAccess}
                onChangeDocumentStatus={changeDocumentStatus}
                onInitialized={() => {
                    const editor = richEditorRef.current?.getEditor();

                    editor.custom.hasChange = () => {
                        setHasChange(true);
                    };
                    setHasChange(false);

                    const zoomModule = new ZoomModule(editor);
                    editor.custom.zoomModule = zoomModule;
                    zoomModule.install();

                    const revisionModule = new RevisionModule(
                        editor,
                        revisionModalRef.current,
                    );
                    editor.custom.revisionModule = revisionModule;
                    revisionModule.install();

                    // Temporarily disable (#64202)
                    // const summaryModule = new SummaryModule(
                    //     editor,
                    //     summaryModalRef.current,
                    // );
                    // editor.custom.summaryModule = summaryModule;
                    // summaryModule.install();

                    const simpleViewModule = new SimpleViewModule(editor);
                    editor.custom.simpleViewModule = simpleViewModule;
                    simpleViewModule.install();

                    const formatPainterModule = new FormatPainterModule(editor);
                    editor.custom.formatPainterModule = formatPainterModule;
                    formatPainterModule.install();

                    // Temporarily disable (#64202)
                    // editor.addShortcut(
                    //     'ctrl + f',
                    //     'abrir modal de busca',
                    //     () => {
                    //         setShowModalFindReplace(true);
                    //     },
                    // );

                    editor.on('contextmenu', () => {
                        setEditorElementImageFocused(null);
                        setEditorElementImageLayoutFocused(null);
                    });

                    /**
                     * @type {function(EditorElementFocusedEvent)}
                     */
                    const editorElementFocused = (e) => {
                        const { element } = e;
                        const contextMenuVisible = isShowingContextMenu();
                        setEditorElementImageFocused(
                            !doc.readOnly &&
                                isEditorElementImage(element) &&
                                !contextMenuVisible
                                ? element
                                : null,
                        );
                        setEditorElementImageLayoutFocused(
                            isEditorElementImageLayout(element) &&
                                !contextMenuVisible
                                ? element
                                : null,
                        );
                    };
                    editor.on('editorElementFocused', editorElementFocused);

                    /**
                     * @type {function(EditorElementFocusedEvent)}
                     */
                    const editorElementBlurred = () => {
                        setEditorElementImageFocused(null);
                        setEditorElementImageLayoutFocused(null);
                    };
                    editor.on('editorElementBlurred', editorElementBlurred);

                    if (!doc.readOnly) {
                        editor.on('click', (event) => {
                            if (getClosestCover(event.target)) {
                                setShowModalCoverContent(true);
                                setEditorCoverElementSelected(
                                    getClosestCover(event.target),
                                );
                            }
                        });
                    }
                }}
                onChange={() => {
                    setHasChange(true);
                }}
                onInput={(e) => {
                    setHasChange(true);
                    richEditorRef.current?.getEditor()?.fire('dataChanged', e);
                }}
                onExportBrailleFacil={exportTxt}
                onExportPdf={async () => {
                    richEditorRef.current?.getEditor().fire('beforeExportPdf');
                    await exportPdfAction(richEditorRef.current?.getEditor());
                    richEditorRef.current?.getEditor().fire('afterExportPdf');
                }}
                onExportDocx={() =>
                    exportDocxAction(richEditorRef.current?.getEditor())
                }
                onUndoManagerAdd={() =>
                    unCacheUndoManager(richEditorRef.current?.getEditor())
                }
                onForceUnlockDocument={forceUnlockDocument}
                onRetrieveImagesDescriptionsBackground={
                    retrieveImagesDescriptionsBackground
                }
                onDictionary={() => setShowDictionaryModal(true)}
                onAddFigure={() => setIsFiguresModalOpen(true)}
                onAddCover={() => setShowModalCover(true)}
                onIdentification={() => setShowIdentificationModal(true)}
                onIdentificationSuppressInAllPagesAction={
                    onIdentificationSuppressInAllPagesAction
                }
                onIdentificationExposeInAllPagesAction={
                    onIdentificationExposeInAllPagesAction
                }
                onPagination={() => setShowPaginationModal(true)}
                isModerator={isModerator}
            />
        </>
    );
}
