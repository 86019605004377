import './RevisionModule.scss';
import { StatusEnum } from './StatusEnum';
import { generateId, isEditorElement } from '../core/EditorUtil';
import { InvalidCharInspection } from './inspections/InvalidCharInspection';
import {
    getCaretPosition,
    scanCaretPath,
    selectRangePath,
    setCaretPosition,
} from '../core/CaretPath';
import { getCurrentPage, getPages } from '../core/PageManipulation';
import { InvalidUseOfApostrophe } from './inspections/InvalidUseOfApostrophe';
import { TextMergedWithQuestionNumber } from './inspections/TextMergedWithQuestionNumber';
import { OrdinalFollowedByTempMeasure } from './inspections/OrdinalFollowedByTempMeasure';
import { DegreeNotFollowedByTempMeasure } from './inspections/DegreeNotFollowedByTempMeasure';
import { CurrencyFollowedBySpace } from './inspections/CurrencyFollowedBySpace';
import { TextMergedWithAlternative } from './inspections/TextMergedWithAlternative';
import { MultiplesSpacesInsideText } from './inspections/MultiplesSpacesInsideText';
import pLimit from 'p-limit';
import { RevisionErrorEnumToString } from './RevisionErrorEnum';
import {
    cachePageIfNotVisible,
    getPageCache,
    isPageCached,
    setPageCacheLock,
} from '../core/Cache';
import { delay } from '../../../../util/Delay';
import { REVISION_MODULE_VERSION } from './RevisionModuleVersion';
import { isDebugEnabled } from '../core/CoreModule';
import { OrdinalNotFollowedBySpace } from './inspections/OrdinalNotFollowedBySpace';

import {
    getEditorElement,
    isInlineEditorElement,
} from '../core/EditorElements';
import {
    removeNonPrintableChars,
    showNonPrintableChars,
} from '../core/ShowNonPrintableChars';
import { ImageNeedsReview } from './inspections/ImageNeedsReview';
import { updateScroll } from "../ScrollModule";

/**
 * @typedef {object} RevisionCheck
 * @property {function(pageNumber: number, page: HTMLElement | Node): Promise<import('RevisionRecord').RevisionRecord[]>} check
 * @property {function()} releaseWorker
 */

/**
 * @type {Object<string, string>}
 */
const statusBarCss = {
    [StatusEnum.OK]: 'status-ok',
    [StatusEnum.WARNING]: 'status-warning',
    [StatusEnum.ERROR]: 'status-error',
};

export const REVISION_LOGGER_ID = '[RevisionModule]';

const CHECK_DOCUMENT_DELAY = 1500;

export class RevisionModule {
    /**
     * @type {EditorCustom}
     */
    editor;
    /**
     * @type {RevisionModalFunctions}
     */
    revisionModal;

    /**
     * @type {HTMLElement | null}
     */
    statusBar = null;

    /**
     * @type {HTMLElement | null}
     */
    statusBarIcon = null;

    /**
     * @type {HTMLElement | null}
     */
    statusBarText = null;

    /**
     * @type {StatusEnumValue}
     */
    currentStatus = StatusEnum.UNAVAILABLE;

    /**
     * @type {Object<number, RevisionRecord[]>}
     */
    revisionRecords = {};

    /**
     * @type {number | null}
     */
    checkDocumentTimer = null;

    /**
     * @type {boolean}
     */
    checkDocumentInExecution = false;

    /**
     * @type {number}
     */
    pageDataChangedLastTime = 0;

    /**
     * @param {EditorCustom} editor
     * @param {RevisionModalFunctions} revisionModal
     */
    constructor(editor, revisionModal) {
        this.editor = editor;
        this.revisionModal = revisionModal;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug(REVISION_LOGGER_ID, ...data);
        }
    }

    /**
     * @return {RevisionCheck[]}
     */
    getRevisionsCheck() {
        return [
            new InvalidCharInspection(),
            new InvalidUseOfApostrophe(),
            new TextMergedWithQuestionNumber(),
            new OrdinalFollowedByTempMeasure(),
            new DegreeNotFollowedByTempMeasure(),
            new CurrencyFollowedBySpace(),
            new TextMergedWithAlternative(),
            new MultiplesSpacesInsideText(),
            new OrdinalNotFollowedBySpace(),
            new ImageNeedsReview(),
        ];
    }

    updateStatusBarStatus() {
        for (const className of Object.values(statusBarCss)) {
            this.statusBar.classList.remove(className.toString());
        }
        switch (this.currentStatus) {
            default:
            case StatusEnum.UNAVAILABLE:
                // I18N
                this.statusBarText.innerText = 'Verificando...';
                // I18N
                this.statusBar.title = 'Verificando documento...';
                break;
            case StatusEnum.OK:
                // I18N
                this.statusBarText.innerText = 'Nenhum problema';
                // I18N
                this.statusBar.title = 'Nenhum problema encontrado';
                break;
            case StatusEnum.WARNING:
                // I18N
                this.statusBarText.innerText = 'Atenção';
                // I18N
                this.statusBar.title = 'Alguns itens requerem sua atenção';
                break;
            case StatusEnum.ERROR:
                // I18N
                this.statusBarText.innerText = 'Problemas encontrados';
                // I18N
                this.statusBar.title = 'Problemas encontrados no documento';
                break;
        }
        const css = statusBarCss[this.currentStatus];
        if (css) this.statusBar.classList.add(css);
    }

    /**
     * @param page {HTMLElement | Node}
     * @param revisionErrors {RevisionRecord[]}
     */
    showRevisionErrors(page, revisionErrors) {
        if (!this.editor.selection) return;
        const bookmark = this.editor.selection.getBookmark();
        if (this.editor.custom.isShowingNonPrintableChars) {
            removeNonPrintableChars(page);
        }
        this.removeRevisionErrors(page);

        this.sortRevisionRecords(revisionErrors);

        for (const revisionError of revisionErrors) {
            /**
             * @type {number}
             */
            let offsetPath;

            let contextElement = page;

            // generate full path and find paragraph offset
            let lastPathLength = -1;
            const path = scanCaretPath(
                page,
                null,
                null,
                (path, paragraph, word, node) => {
                    if (lastPathLength === path.length) return true;
                    lastPathLength = path.length;
                    if (isEditorElement(node) && !isInlineEditorElement(node)) {
                        path[path.length - 1] = node;
                    }
                    if (paragraph === revisionError.paragraph) {
                        if (offsetPath == null) {
                            offsetPath = path.length - 1;
                        }
                        const currentPath = path[path.length - 1];
                        if (isEditorElement(currentPath)) {
                            if (isInlineEditorElement(currentPath)) {
                                if (
                                    revisionError.pathIndex + offsetPath >=
                                    lastPathLength - 1
                                ) {
                                    const subPath = scanCaretPath(
                                        currentPath,
                                        null,
                                        null,
                                        null,
                                        true,
                                    );
                                    offsetPath -= subPath.length - 1;
                                }
                            } else {
                                const editorElement = getEditorElement(node);
                                /**
                                 * @type {HTMLElement[]}
                                 */
                                let containers;
                                if (editorElement.getInnerContextContainers) {
                                    containers =
                                        editorElement.getInnerContextContainers(
                                            node,
                                        );
                                } else {
                                    containers = [];
                                    for (const containerClass of editorElement.getInnerContextContainerCssClass()) {
                                        const container =
                                            node.querySelector(containerClass);
                                        if (!container) continue;
                                        containers.push(container);
                                    }
                                }

                                contextElement =
                                    containers[revisionError.fragment];
                                if (!contextElement) return false;
                                offsetPath = 0;
                                const newPath = scanCaretPath(
                                    contextElement,
                                    null,
                                    null,
                                    null,
                                    true,
                                );
                                path.splice(0, path.length - 1, ...newPath);
                                return false;
                            }
                        }
                    }
                },
            );

            const startPath = path.slice(
                0,
                offsetPath + revisionError.pathIndex,
            );
            const endPath = path.slice(
                0,
                offsetPath + revisionError.pathIndex + revisionError.length,
            );

            selectRangePath(this.editor, contextElement, startPath, endPath);
            const revisionErrorElement = this.editor.dom.create('span', {
                class: 'revision-error',
            });
            this.updateEditorElementRevisionError(revisionErrorElement, [
                revisionError,
            ]);

            revisionErrorElement.innerHTML = this.editor.selection.getContent();
            this.editor.selection.setContent(revisionErrorElement.outerHTML);
        }

        if (this.editor.custom.isShowingNonPrintableChars) {
            showNonPrintableChars(this.editor, page);
        }
        this.editor.selection.moveToBookmark(bookmark);
    }

    /**
     * @param editorElement {HTMLElement}
     * @return {string}
     */
    getEditorElementRevisionErrorId(editorElement) {
        let editorElementId = editorElement.getAttribute('id');
        if (!editorElementId) {
            editorElementId = generateId(
                this.editor,
                'editor-element-revision-error',
            );
            editorElement.setAttribute('id', editorElementId);
        }
        return editorElementId;
    }

    /**
     * @param element {HTMLElement}
     * @param revisionErrors {RevisionRecord[]}
     */
    updateEditorElementRevisionError(element, revisionErrors) {
        /**
         * @type {RevisionRecord[]}
         */
        let dataRevisionError = JSON.parse(
            element.getAttribute('data-revision-error'),
        );
        const editorElementId = this.getEditorElementRevisionErrorId(element);
        if (!dataRevisionError) {
            dataRevisionError = [];
        }
        dataRevisionError.push(...revisionErrors);
        element.setAttribute(
            'data-revision-error',
            JSON.stringify(dataRevisionError),
        );
        let title = '';
        for (const error of dataRevisionError) {
            title += RevisionErrorEnumToString(error.inspectionError) + '\n';
            error.editorElementId = editorElementId;
        }
        element.setAttribute('title', title);
    }

    /**
     * @param revisionErrors {RevisionRecord[]}
     */
    sortRevisionRecords(revisionErrors) {
        revisionErrors.sort((a, b) => {
            if (a.page === b.page) {
                if (a.paragraph === b.paragraph) {
                    if (a.fragment === b.fragment) {
                        return a.pathIndex - b.pathIndex;
                    } else {
                        return a.fragment - b.fragment;
                    }
                } else {
                    return a.paragraph - b.paragraph;
                }
            } else {
                return a.page - b.page;
            }
        });
    }

    /**
     * @return {Promise<void>}
     */
    async checkDocument() {
        if (this.checkDocumentInExecution) return;
        clearTimeout(this.checkDocumentTimer);
        this.currentStatus = StatusEnum.UNAVAILABLE;
        this.updateStatusBarStatus();

        const self = this;
        this.checkDocumentTimer = setTimeout(async () => {
            if (this.checkDocumentInExecution) return;
            this.checkDocumentInExecution = true;
            let checkDocumentAgain = false;
            const revisionsChecks = self.getRevisionsCheck();
            try {
                const pages = getPages(self.editor);
                for (let [pageIdx, page] of pages.entries()) {
                    const pageRevisionVersion = parseInt(
                        page.getAttribute('data-revision-version'),
                    );
                    const revisionOutdated =
                        isNaN(pageRevisionVersion) ||
                        pageRevisionVersion < REVISION_MODULE_VERSION;

                    if (
                        !revisionOutdated &&
                        page.getAttribute('data-needs-revision') === 'false'
                    ) {
                        continue;
                    }
                    self.debug(
                        `Checking page ${pageIdx + 1} for revision errors...`,
                    );
                    const start = new Date().getTime();
                    await this.checkPage(revisionsChecks, pageIdx, page);
                    self.debug(
                        `Page checked after ${new Date().getTime() - start} ms.`,
                    );
                    // user typing, slowdown document check to not hit page performance
                    if (
                        new Date().getTime() - self.pageDataChangedLastTime <
                        CHECK_DOCUMENT_DELAY
                    ) {
                        checkDocumentAgain = true;
                        return;
                    }
                    await delay(0);
                }
                self.checkDocumentTimer = null;
                this.updateCurrentStatus().then();
                updateScroll(this.editor);
            } finally {
                for (const revisionCheck of revisionsChecks) {
                    revisionCheck.releaseWorker();
                }
                this.checkDocumentInExecution = false;
                if (checkDocumentAgain) self.checkDocument().then();
            }
        }, CHECK_DOCUMENT_DELAY);
    }

    /**
     * @param revisionsChecks {RevisionCheck[]}
     * @param pageIdx {number}
     * @param page {HTMLElement}
     * @returns {Promise<RevisionRecord[]>}
     */
    async checkPage(revisionsChecks, pageIdx, page) {
        const limit = pLimit(4);
        setPageCacheLock(this.editor, page, true);
        const pageRevisionRecords = [];
        try {
            const promises = [];
            for (const revisionCheck of revisionsChecks) {
                promises.push(
                    limit(async () => {
                        pageRevisionRecords.push(
                            ...(await revisionCheck.check(pageIdx, page)),
                        );
                    }),
                );
            }
            // noinspection JSUnresolvedReference
            await Promise.allSettled(promises);
            this.showRevisionErrors(page, pageRevisionRecords);
        } finally {
            setPageCacheLock(this.editor, page, false);
            cachePageIfNotVisible(this.editor, page);
        }

        this.revisionRecords[pageIdx] = pageRevisionRecords;
        page.setAttribute('data-needs-revision', 'false');
        page.setAttribute(
            'data-revision-version',
            REVISION_MODULE_VERSION.toString(),
        );
        return pageRevisionRecords;
    }

    async updateCurrentStatus() {
        let newStatus = StatusEnum.OK;
        for (const page in this.revisionRecords) {
            const pageRevisionRecords = this.revisionRecords[page];
            for (const revisionRecord of pageRevisionRecords) {
                if (revisionRecord.revisionGravity > newStatus) {
                    newStatus = revisionRecord.revisionGravity;
                }
            }
        }
        this.currentStatus = newStatus;
        await this.updateStatusBarStatus();
    }

    harvestInspectionsErrors() {
        this.revisionRecords = [];
        const pages = getPages(this.editor);
        for (let [pageIdx, page] of pages.entries()) {
            if (page.getAttribute('data-needs-revision') !== 'false') {
                continue;
            }

            if (isPageCached(page)) {
                page = getPageCache(this.editor, page, false) ?? page;
            }

            /**
             * @type {HTMLElement[]}
             */
            const editorElements = [
                ...page.querySelectorAll('[data-revision-error]'),
            ];
            let pageRevisionRecords = [];
            for (const editorElement of editorElements) {
                const dataRevisionError = editorElement.getAttribute(
                    'data-revision-error',
                );
                if (!dataRevisionError) {
                    // something is broken
                    console.warn(
                        'Inspection error data not found. The page will be checked again.',
                        page,
                    );
                    this.pageNeedsRevision(pages[pageIdx]);
                    pageRevisionRecords = [];
                    break;
                }
                try {
                    /**
                     * @type {RevisionRecord[]}
                     */
                    const editorRevisionErrors = JSON.parse(dataRevisionError);
                    pageRevisionRecords.push(...editorRevisionErrors);
                    const editorElementId =
                        this.getEditorElementRevisionErrorId(editorElement);
                    for (const revisionError of editorRevisionErrors) {
                        revisionError.editorElementId = editorElementId;
                    }
                } catch (e) {
                    console.error(
                        'Fail to parse inspection error data. The page will be checked again.',
                        e,
                    );
                    pages[pageIdx].setAttribute('data-needs-revision', 'true');
                    pageRevisionRecords = [];
                }
            }
            this.revisionRecords[pageIdx] = pageRevisionRecords;
        }
    }

    /**
     * @return {RevisionRecord[]}
     */
    getAllRevisionRecords() {
        /**
         * @type {RevisionRecord[]}
         */
        const allRecords = [];
        for (const pageRevisionRecord of Object.values(this.revisionRecords)) {
            allRecords.push(...pageRevisionRecord);
        }
        return allRecords;
    }

    showRevisionModal() {
        this.revisionModal.showRevisionModal(
            this.editor,
            this.getAllRevisionRecords(),
        );
    }

    /**
     * @param page {HTMLElement | null}
     */
    pageNeedsRevision(page) {
        page?.setAttribute('data-needs-revision', 'true');
    }

    createStatusBarItem() {
        const container = this.editor.custom.customStatusBarContainer;
        this.statusBar = document.createElement('div');
        this.statusBar.className = 'revision-module-status-bar';
        this.statusBarIcon = document.createElement('div');
        this.statusBarIcon.className = 'status-icon';
        this.statusBar.appendChild(this.statusBarIcon);

        this.statusBarText = document.createElement('div');
        this.statusBarText.className = 'status-text';
        this.statusBar.appendChild(this.statusBarText);

        container.appendChild(this.statusBar);

        this.statusBar.addEventListener('click', () =>
            this.showRevisionModal(),
        );

        this.updateStatusBarStatus();
    }

    /**
     * @param page {HTMLElement}
     */
    removeRevisionErrors(page) {
        const caretPosition =
            this.editor.selection.getNode()?.className === 'revision-error'
                ? getCaretPosition(this.editor)
                : null;
        for (const revisionError of [
            ...page.querySelectorAll('[type="revision-error"]'),
            ...page.querySelectorAll('.revision-error'),
        ]) {
            const fragment = document.createDocumentFragment();
            fragment.append(...revisionError.childNodes);
            revisionError.replaceWith(fragment);
        }
        if (caretPosition) {
            setCaretPosition(
                this.editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
        }
    }

    install() {
        this.createStatusBarItem();

        const self = this;
        /**
         * @param e {PageDataChangedEvent}
         */
        const pageUpdated = (e) => {
            const caretPosition = e['caretPosition'];
            self.pageNeedsRevision(caretPosition?.page);
            self.pageDataChangedLastTime = new Date().getTime();
            self.checkDocument().then();
        };
        this.editor.on('pageUpdated', pageUpdated);

        this.editor.on('beforeInput', () => {
            const page = getCurrentPage(self.editor);
            if (!page) return;
            self.removeRevisionErrors(page);
        });

        this.editor.on('keyDown', () => {
            self.checkDocument().then();
            self.pageDataChangedLastTime = new Date().getTime();
        });

        /**
         * @param e {PageRemovedEvent}
         * @returns {Promise<void>}
         */
        const pageRemoved = async (e) => {
            const { pageIdx } = e;
            delete this.revisionRecords[pageIdx];
            await this.updateCurrentStatus();
        };
        this.editor.on('pageRemoved', pageRemoved);
        this.editor.on('documentReady', () => {
            self.harvestInspectionsErrors();
            self.checkDocument().then();
        });

        /**
         * @param e {PageOutdatedEvent}
         */
        const pageOutdated = (e) => {
            const page = e.page;
            self.pageNeedsRevision(page);
        };
        this.editor.on('pageOutdated', pageOutdated);
    }

    /**
     * @param revisionError {RevisionErrorEnum | RevisionErrorEnum[]}
     * @returns {boolean}
     */
    hasRevisionErrors(revisionError) {
        /**
         * @type {Set<RevisionErrorEnum>}
         */
        const revisionErrorToVerify = new Set();
        if (Array.isArray(revisionError)) {
            revisionError.forEach((error) => revisionErrorToVerify.add(error));
        } else {
            revisionErrorToVerify.add(revisionError);
        }

        for (const records of Object.values(this.revisionRecords)) {
            for (const record of records) {
                if (revisionErrorToVerify.has(record.inspectionError)) {
                    return true;
                }
            }
        }
        return false;
    }
}
