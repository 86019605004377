import { Version1 } from './Version1';
import { Version2 } from './Version2';
import { Version5 } from './Version5';
import { Version6 } from './Version6';
import { Version8 } from './Version8';
import { Version9 } from "./Version9";

/**
 * @type {DocumentUpdateVersion[]}
 */
const versions = [
    new Version1(),
    new Version2(),
    new Version5(),
    new Version6(),
    new Version8(),
    new Version9()
];

export default versions;
