import * as FileSaver from 'file-saver';
import {
    Document,
    ImageRun,
    Paragraph,
    Packer,
    TextRun,
    AlignmentType,
    PageOrientation,
} from 'docx';
import { downloadToBase64 } from '../../util/DownloadToBase64';
import { getImageDimensions } from '../../util/GetImageDimensions';
import { getImageUrlDataFromBackgroundImage } from 'plataforma-braille-common';
import { normalizeSpaces, removeInvisibleSpacesFromText } from "../../util/TextUtil";

/**
 * @param node {Node | HTMLElement}
 * @param state {{bold: boolean | undefined, underline: boolean | undefined, italic: boolean | undefined} | undefined}
 */
function extractRecursively(node, state = undefined) {
    const output = [];
    if (!node) {
        return output;
    }
    if (node.nodeType === Node.TEXT_NODE) {
        output.push(
            new TextRun({
                text: normalizeSpaces(removeInvisibleSpacesFromText(node.textContent)),
                ...state,
            }),
        );
    } else if (node.tagName === 'BR') {
        output.push(new TextRun({ text: '', break: 1 }));
    } else {
        if (!state) {
            state = {};
        }
        if (node.tagName === 'STRONG') {
            state.bold = true;
        } else if (node.style?.textDecoration === 'underline') {
            state.underline = true;
        } else if (node.tagName === 'EM') {
            state.italic = true;
        }
        for (const child of node.childNodes) {
            output.push(...extractRecursively(child, { ...state }));
        }
    }

    return output;
}

/**
 * @param doc {BrailleDocument}
 * @param pages {HTMLElement[]}
 * @param progressFn {function(current:number, total:number)}
 */
export async function exportDocx(doc, pages, progressFn) {
    const sections = [];

    const children = [];
    for (const [pageIdx, page] of pages.entries()) {
        progressFn(pageIdx + 1, pages.length);
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...page.querySelectorAll('editor-element[type="image"]'),
        ];
        for (const elementImage of elements) {
            const { backgroundImage } = getComputedStyle(elementImage);
            const image = getImageUrlDataFromBackgroundImage(backgroundImage);
            let imageData;
            imageData = await downloadToBase64(image, false);
            imageData = imageData.split(',')[1];

            let { width: imgWidth, height: imgHeight } =
                await getImageDimensions(imageData);

            if (imgWidth > 600) {
                const hReason = imgHeight / imgWidth;
                imgWidth = 600;
                imgHeight = imgWidth * hReason;
            }
            if (imgHeight > 300) {
                const wReason = imgWidth / imgHeight;
                imgHeight = 300;
                imgWidth = imgHeight * wReason;
            }

            const pageNumber = elementImage.querySelector('.page-number');
            const imageDescription =
                elementImage.querySelector('.info-description');
            const externalId = elementImage.getAttribute('data-external-id');

            children.push(
                ...[
                    new Paragraph({
                        children: [
                            new TextRun('Página '),
                            ...extractRecursively(pageNumber),
                        ],
                        style: 'PageNumber',
                    }),
                    new Paragraph({
                        children: [
                            new ImageRun({
                                data: imageData,
                                transformation: {
                                    width: imgWidth,
                                    height: imgHeight,
                                },
                            }),
                        ],
                        style: 'Image',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun('ID: '),
                            new TextRun(externalId ?? 'indisponível'),
                        ],
                        style: 'ImageDescription',
                    }),
                    new Paragraph({
                        children: extractRecursively(imageDescription),
                        style: 'ImageDescription',
                    }),
                ],
            );
        }
    }

    const pageMargins = '1.27cm';
    sections.push({
        properties: {
            page: {
                margin: {
                    top: pageMargins,
                    right: pageMargins,
                    bottom: pageMargins,
                    left: pageMargins,
                },
                size: {
                    height: '297mm',
                    width: '210mm',
                    orientation: PageOrientation.PORTRAIT,
                },
            },
        },
        children,
    });

    // let fontName = doc.inkFontType;
    // let fontSize = doc.inkFontSize;
    //
    // switch (fontName) {
    //     case FontNameEnum.NIMBUS_ROMAN:
    //         fontName = 'Nimbus Roman';
    //         break;
    //     default:
    //     case FontNameEnum.DEJAVU_SANS:
    //         fontName = 'DeJavu Sans';
    //         break;
    //     case FontNameEnum.DEJAVU_SANS_BOLD:
    //         fontName = 'DeJavu Sans Bold';
    //         break;
    // }

    const fontName = 'Arial';
    const fontSize = 12;
    const docx = new Document({
        creator: 'Plataforma Braille',
        title: doc.name,
        styles: {
            paragraphStyles: [
                {
                    id: 'PageNumber',
                    name: 'Page number',
                    run: {
                        size: fontSize * 2,
                        color: '000000',
                        font: { name: fontName },
                    },
                    paragraph: {
                        spacing: {
                            before: fontSize * 20,
                            after: 0,
                        },
                        alignment: AlignmentType.JUSTIFIED,
                    },
                },
                {
                    id: 'Image',
                    name: 'Image',
                },
                {
                    id: 'ImageDescription',
                    name: 'Image description',
                    basedOn: 'Normal',
                    next: 'Normal',
                    run: {
                        size: fontSize * 2,
                        color: '000000',
                        font: { name: fontName },
                    },
                    paragraph: {
                        spacing: {
                            before: fontSize * 20,
                            after: 0,
                        },
                        alignment: AlignmentType.JUSTIFIED,
                    },
                },
            ],
        },
        sections,
    });

    const blob = await Packer.toBlob(docx);
    FileSaver.saveAs(blob, `${doc.name}.docx`);
}
