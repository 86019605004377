/**
 * @implements {DocumentUpdateVersion}
 */
export class Version9 {
    /**
     * @returns {number}
     */
    version() {
        return 9;
    }

    /**
     * @returns {Promise<void>}
     */
    async updatePage() {
        // do nothing, just force all pages updated to solve ticket #69471
    }
}
