import {
    getRootNodeInPage,
    isEditorElementParagraphBreak,
    isEditorPage,
    pageHasText,
    removeNextSiblingChain,
    replaceSelectedNodes,
    getClosestPage,
} from './EditorUtil';
import {
    getCaretPosition,
    getLineCount,
    scanCaretPath,
    setCaretPosition,
} from './CaretPath';
import { updateScroll } from '../ScrollModule';
import { uncachePage } from './Cache';
import { isInnerContextElement } from './EditorElements';
import { isEditorBrailleView } from './BrailleView';

/**
 * @typedef {object} PageAppendedEvent
 * @property {HTMLElement} page
 */

/**
 * @typedef {object} PageRemovedEvent
 * @property {HTMLElement} page
 * @property {number} pageIdx
 */

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement[]}
 */
export function getPages(editor) {
    return editor?.dom?.select('editor-page') ?? [];
}

/**
 * @param editor {EditorCustom}
 * @return {HTMLElement | null}
 */
export function getCurrentPage(editor) {
    let walk = editor?.selection?.getNode();
    while (walk) {
        if (isEditorPage(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param editor {EditorCustom}
 * @return {HTMLElement}
 */
export function insertBlankPage(editor) {
    let newPage;
    editor.undoManager.transact(() => {
        const root = editor.dom.getRoot();
        newPage = createNewPage(editor);
        const pages = getPages(editor);
        const currentPage = getCurrentPage(editor);
        const currentPageIndex =
            currentPage == null ? pages.length - 1 : pages.indexOf(currentPage);
        if (currentPageIndex < pages.length - 1) {
            root.insertBefore(newPage, pages[currentPageIndex + 1]);
        } else {
            root.append(newPage);
        }
    });
    newPage.setAttribute('data-needs-update', 'true');
    /**
     * @type {PageAppendedEvent}
     */
    const pageAppendedEvent = {
        page: newPage,
    };
    editor.fire('pageAppended', pageAppendedEvent);
    return newPage;
}

export function getBrailleView(page) {
    return page?.querySelector('editor-braille-view');
}

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement}
 */
export function insertPageBreak(editor) {
    editor.undoManager.add();
    const br = document.createElement('br');
    replaceSelectedNodes(editor, [br]);
    const topNode = getRootNodeInPage(br);
    const currentPage = getClosestPage(topNode);
    editor.custom?.excessLinesControlModule?.setLineCount(currentPage, null);

    let walk = topNode.nextSibling;
    let toMove = [];
    while (walk) {
        toMove.push(walk);
        walk = walk.nextSibling;
    }
    removeNextSiblingChain(br, topNode.parentElement);

    let newPage = insertBlankPage(editor);
    for (let node of toMove) {
        newPage.append(node);
    }
    if (newPage.childNodes[0].nodeType === Node.TEXT_NODE) {
        editor.selection.setCursorLocation(newPage.childNodes[0], 0);
    } else {
        editor.selection.setCursorLocation(newPage, 0);
    }
    // braille view was moved to new page, back to the original
    const brailleView = getBrailleView(newPage);
    if (brailleView) {
        newPage.previousSibling.appendChild(brailleView);
    }
    return newPage;
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement}
 */
export function addParagraphBreakAboveElement(editor, element) {
    editor.undoManager.transact(() => {
        const br = document.createElement('br');
        element.before(br);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement}
 */
export function addParagraphBreakBellowElement(editor, element) {
    editor.undoManager.transact(() => {
        /**
         * @type {HTMLElement | null}
         */
        const nextSibling = element.nextElementSibling;
        if (
            nextSibling?.tagName === 'BR' &&
            nextSibling?.style?.display === 'none'
        ) {
            element = nextSibling;
        }
        /**
         * @type {Node}
         */
        const br = document.createElement('br');
        element.after(br);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 *
 * @param editor {EditorCustom}
 * @returns {HTMLElement}
 */
export function createNewPage(editor) {
    /**
     * @type {HTMLElement}
     */
    const page = editor.dom.create('editor-page', {
        'data-document-version': editor.custom?.coreModule?.documentUpdate
            ?.getDocumentVersion()
            .toString(),
        'data-needs-update': 'true',
    });
    page.append(document.createElement('BR'));
    return page;
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement}
 * @returns {HTMLElement}
 */
export function createNewPageAfter(editor, page) {
    const nextPage = createNewPage(editor);
    page.after(nextPage);
    /**
     * @type {PageAppendedEvent}
     */
    const pageAppendedEvent = {
        page: nextPage,
    };
    editor.fire('pageAppended', pageAppendedEvent);
    return nextPage;
}

/**
 * @param element {HTMLElement}
 * @return {HTMLElement | null}
 */
export function getNextPage(element) {
    const currentPage = getClosestPage(element);
    if (!currentPage) return null;
    let walk = currentPage;
    do {
        walk = walk.nextElementSibling;
        if (isEditorPage(walk)) {
            return walk;
        }
    } while (walk);
    return null;
}
/**
 * @param element {HTMLElement}
 * @return {HTMLElement | null}
 */
export function getPreviousPage(element) {
    const currentPage = getClosestPage(element);
    if (!currentPage) return null;
    let walk = currentPage;
    do {
        walk = walk.previousElementSibling;
        if (isEditorPage(walk)) {
            return walk;
        }
    } while (walk);
    return null;
}

/**
 * @param editor {EditorCustom}
 * @param page {Node}
 * @returns {boolean}
 */
export function removeEmptyPage(editor, page) {
    if (!isEditorPage(page)) return false;
    if (!pageHasText(page) && getLineCount(page) <= 2) {
        let selectPage;
        let selectPath;
        const nextPage = getNextPage(page);
        if (nextPage) {
            selectPage = nextPage;
            selectPath = [];
        } else if (page.previousSibling) {
            selectPage = page.previousSibling;
            selectPath = scanCaretPath(selectPage);
            selectPath.pop();
        }
        if (selectPage) {
            editor.undoManager.transact(() => {
                uncachePage(editor, page);
                uncachePage(editor, selectPage);
                const pageIdx = getPages(editor).indexOf(page);
                page.remove();
                /**
                 * @type {PageRemovedEvent}
                 */
                const pageRemovedEvent = {
                    page: page,
                    pageIdx: pageIdx,
                };
                editor.fire('pageRemoved', pageRemovedEvent);
                setCaretPosition(editor, selectPage, selectPath);
                setTimeout(() => {
                    updateScroll(editor);
                }, 100);
            });
            editor.custom.hasChange();
            return true;
        }
    }
    return false;
}

/**
 * @param page {HTMLElement | Node}
 */
export function getPageTermination(page) {
    let walk = page.lastElementChild;
    while (walk && isEditorBrailleView(walk)) {
        walk = walk.previousElementSibling;
    }
    if (walk && walk.tagName === 'BR') {
        return walk;
    }
    return null;
}

/**
 * All pages must have a BR in the end of page to avoid bizarres behaviors with cursor
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 * @returns {boolean}
 */
export function fixPageTermination(editor, page) {
    let termination = getPageTermination(page);
    if (!termination) {
        termination = document.createElement('br');
        page.appendChild(termination);
    }
}

/**
 * @param page {HTMLElement}
 * @param brailleView {HTMLElement | null}
 */
export function appendBrailleView(page, brailleView) {
    if (brailleView) {
        page.append(brailleView);
    }
}

/**
 * @param page {HTMLElement | Node}
 * @param content {HTMLElement | Node}
 */
export function replacePageContents(page, content) {
    const brailleView = getBrailleView(page);
    page.innerText = '';
    page.appendChild(content);
    appendBrailleView(page, brailleView);
}

/**
/**
 * @param page {HTMLElement | DocumentFragment}
 * @param allLineBreaks {boolean}
 * @returns {[HTMLElement[]]}
 */
export function getPageLineBreaks(page, allLineBreaks) {
    let paragraphs = [];
    let paragraph = [];
    for (let child of page.childNodes) {
        if (isEditorBrailleView(child)) continue;

        const lineBreak =
            isEditorElementParagraphBreak(child) ||
            isInnerContextElement(child);

        if (child?.tagName === 'BR' || (allLineBreaks && lineBreak)) {
            paragraph.push(child);
            // maybe will be other elements that break a line
            paragraphs.push(paragraph);
            paragraph = [];
            continue;
        }
        paragraph.push(child);
    }
    if (paragraph.length && !paragraphs.includes(paragraph)) {
        paragraphs.push(paragraph);
    }
    return paragraphs;
}

/**
 * @param page {HTMLElement | DocumentFragment}
 * @returns {[HTMLElement[]]}
 */
export function getPageParagraphs(page) {
    return getPageLineBreaks(page, false);
}

/**
 * @param editor {EditorCustom}
 * @param page {number}
 * @return {number}
 */
export function goToPage(editor, page) {
    const pages = getPages(editor);
    if (page < 0) {
        page = 0;
    } else if (page >= pages.length) {
        page = pages.length - 1;
    }
    const targetPage = pages[page];
    setTimeout(() => {
        const htmlElement = editor.getBody().parentElement;
        const top = targetPage.offsetTop * editor.custom.zoom;
        htmlElement.scrollTo({
            behavior: 'smooth',
            top,
        });
    }, 0);
    setTimeout(() => {
        highlightPage(targetPage);
    }, 300);
    return page;
}

/**
 * @param page {HTMLElement}
 */
export function highlightPage(page) {
    page.classList.add('highlight');
    setTimeout(() => {
        page.classList.remove('highlight');
    }, 500);
}

/**
 *
 * @param editor {EditorCustom}
 * @returns {HTMLElement}
 */
export function createNewDocumentBreak(editor) {
    return editor.dom.create('document-break');
}

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement[]}
 */
export function getDocumentBreaks(editor) {
    return editor?.dom?.select('document-break') ?? [];
}

/**
 * @param element {HTMLElement}
 * @returns {boolean}
 */
export function isDocumentBreaks(element) {
    return element?.tagName === 'DOCUMENT-BREAK';
}

/**
 * @param editor
 * @returns {HTMLElement}
 */
export function insertDocumentBreak(editor) {
    const currentPage = getCurrentPage(editor);

    if (!isDocumentBreaks(currentPage?.nextElementSibling)) {
        editor.undoManager.transact(() => {
            const newPage = insertPageBreak(editor);
            const root = editor.dom.getRoot();
            const documentBreak = createNewDocumentBreak(editor);
            const title = document.createElement('div');
            // I18N
            title.innerText = 'Quebra de parte';
            title.setAttribute('contenteditable', 'false');
            documentBreak.appendChild(title);
            root.insertBefore(documentBreak, newPage);
        });
    }
}

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement[]}
 */
export function getAllPageElementsFromEditor(editor) {
    return editor?.dom?.select('editor-page,document-break,cover-page') ?? [];
}

/**
 *
 * @param editor {EditorCustom}
 * @returns {HTMLElement}
 */
export function createNewCoverPage(editor) {
    return editor.dom.create('cover-page');
}

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement | null}
 */
export function getCoverPage(editor) {
    return editor?.dom?.select('cover-page')[0];
}

/**
 * @param element {HTMLElement}
 * @returns {boolean}
 */
export function isCoverPage(element) {
    return element?.tagName === 'COVER-PAGE';
}

/**
 * @param editor {EditorCustom}
 */
export function removeCoverPage(editor) {
    const coverPage = getCoverPage(editor);
    if (coverPage) {
        editor?.dom?.remove(coverPage);
    }
}

/**
 * @param editor {EditorCustom}
 * @param cover {CoversDto}
 * @returns {HTMLElement}
 */
export function insertCoverPage(editor, cover) {
    editor.undoManager.transact(() => {
        const root = editor.dom.getRoot();
        const coverPage = createNewCoverPage(editor);

        //Cover
        const divCover = document.createElement('div');
        divCover.classList.add('cover');

        const divCoverLabel = document.createElement('div');
        divCoverLabel.classList.add('label');
        divCoverLabel.innerText = 'Conteúdo da capa:';
        divCoverLabel.setAttribute('contenteditable', 'false');

        const divCoverContent = document.createElement('div');
        divCoverContent.classList.add('content');
        divCoverContent.setAttribute('data-type', 'cover');
        divCoverContent.innerText = cover.cover;
        divCoverContent.setAttribute('contenteditable', 'false');

        divCover.appendChild(divCoverLabel);
        divCover.appendChild(divCoverContent);

        //Back cover
        const divBackCover = document.createElement('div');
        divBackCover.classList.add('cover');

        const divBackCoverLabel = document.createElement('div');
        divBackCoverLabel.classList.add('label');
        divBackCoverLabel.innerText = 'Conteúdo da folha de rosto:';
        divBackCoverLabel.setAttribute('contenteditable', 'false');

        const divBackCoverContent = document.createElement('div');
        divBackCoverContent.classList.add('content');
        divBackCoverContent.setAttribute('data-type', 'back-cover');
        divBackCoverContent.innerText = cover.backCover;
        divBackCoverContent.setAttribute('contenteditable', 'false');

        divBackCover.appendChild(divBackCoverLabel);
        divBackCover.appendChild(divBackCoverContent);

        //Technical sheet
        const divTechnicalSheet = document.createElement('div');
        divTechnicalSheet.classList.add('technical-sheet');

        const divTechnicalSheetLabel = document.createElement('div');
        divTechnicalSheetLabel.classList.add('label');
        divTechnicalSheetLabel.innerText = 'Conteúdo da ficha técnica:';
        divTechnicalSheetLabel.setAttribute('contenteditable', 'false');

        const divTechnicalSheetContent = document.createElement('div');
        divTechnicalSheetContent.classList.add('content');
        divTechnicalSheetContent.setAttribute('data-type', 'technical-sheet');
        divTechnicalSheetContent.innerText = cover.technicalSheet;
        divTechnicalSheetContent.setAttribute('contenteditable', 'false');

        divTechnicalSheet.appendChild(divTechnicalSheetLabel);
        divTechnicalSheet.appendChild(divTechnicalSheetContent);

        coverPage.appendChild(divCover);
        coverPage.appendChild(divBackCover);
        coverPage.appendChild(divTechnicalSheet);
        root.insertBefore(coverPage, editor.dom.getRoot().firstChild);
    });
}
